import clsx from 'clsx';
import type { PropsWithChildren } from 'react';

export type GapSizes = 'small' | 'medium' | 'large' | 'extra-large';

type Props = {
  direction?: 'vertical' | 'horizontal';
  size?: GapSizes;
  alignItems?: 'center' | 'start' | 'end';
  justifyContent?: 'space-between' | 'flex-start' | 'flex-end' | 'space-around';
  flex?: boolean;
  wrap?: boolean;
  id?: string;
};

export const Gap = ({
  direction = 'vertical',
  size = 'medium',
  alignItems,
  justifyContent,
  children,
  flex,
  wrap,
  id,
}: PropsWithChildren<Props>) => (
  <>
    <div
      id={id}
      className={clsx('Gap', {
        'Gap--horizontal': direction === 'horizontal',
        'Gap--vertical': direction === 'vertical',
        'Gap--align-items-center': alignItems === 'center',
        'Gap--align-items-start': alignItems === 'start',
        'Gap--align-items-end': alignItems === 'end',
        'Gap--wrap': wrap,
        'Gap--justify-content-flex-end': justifyContent === 'flex-end',
        'Gap--justify-content-space-between':
          justifyContent === 'space-between',
        'Gap--justify-content-space-around': justifyContent === 'space-around',
        'Gap--justify-content-flex-start': justifyContent === 'flex-start',
        'Gap--small': size === 'small',
        'Gap--medium': size === 'medium',
        'Gap--large': size === 'large',
        'Gap--extra-large': size === 'extra-large',
        'Gap--flex': flex,
      })}
    >
      {children}
    </div>
    <style global jsx>{`
      .Gap {
        display: flex;
      }
      .Gap--flex {
        flex: 1;
      }
      .Gap--wrap {
        flex-wrap: wrap;
      }
      .Gap--horizontal {
        flex-direction: row;
      }
      .Gap--align-items-center {
        align-items: center;
      }
      .Gap--align-items-start {
        align-items: start;
      }
      .Gap--align-items-end {
        align-items: end;
      }
      .Gap--justify-content-space-between {
        justify-content: space-between;
      }
      .Gap--justify-content-space-around {
        justify-content: space-around;
      }
      .Gap--justify-content-flex-start {
        justify-content: flex-start;
      }
      .Gap--justify-content-flex-end {
        justify-content: flex-end;
      }
      .Gap--vertical {
        flex-direction: column;
      }
      .Gap--small {
        gap: 0.5rem;
      }
      .Gap--medium {
        gap: 1rem;
      }
      .Gap--large {
        gap: 1.5rem;
      }
      .Gap--extra-large {
        gap: 2rem;
      }
    `}</style>
  </>
);
