import { useWarning } from '@src/contexts/warning-provider/warning-provider';
import * as colors from '@src/support/colors';

import { useField } from 'formik';

export const useInputStyle = (name: string) => {
  const [_field, meta] = useField(name);
  const { getWarning } = useWarning();
  const warning = getWarning(name);
  const hasError = !!meta.error && meta.touched;
  const hasWarning = !!warning && meta.touched;

  return hasError ? errorSx : hasWarning ? warningSx : defaultSx;
};

const buildSx = (borderColor: string) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: colors.white,
    '& fieldset': {
      borderColor,
    },
    '&:hover fieldset': {
      borderColor,
    },
    '&.Mui-focused fieldset': {
      borderColor,
    },
    '&.Mui-disabled': {
      backgroundColor: colors.neutralLower,

      '& fieldset': {
        borderColor: colors.neutralHigh,
      },

      '&:hover fieldset': {
        borderColor: colors.neutralHigh,
      },

      '& input::placeholder': {
        textFillColor: colors.neutralHigh,
        opacity: 1,
      },
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor,
  },
  '&.Mui-disabled': {
    borderColor: colors.neutralHigh,
    backgroundColor: colors.neutralLower,
  },
});

const warningSx = buildSx(colors.semanticWarning);

const errorSx = buildSx(colors.semanticError);

const defaultSx = buildSx(colors.neutralDefault);
