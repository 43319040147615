import { Field, type FieldProps } from '../internal/field';
import { TextInput, type TextInputProps } from './internal/text-input';

type TextFieldProps = TextInputProps & FieldProps;

export const TextField = ({
  label,
  description,
  tooltip,
  required,
  gap,
  helperText,
  noWrapLabel,
  ...props
}: TextFieldProps) => (
  <Field
    name={props.name}
    label={label}
    required={required}
    tooltip={tooltip}
    description={description}
    gap={gap}
    helperText={helperText}
    noWrapLabel={noWrapLabel}
  >
    <TextInput {...props} />
  </Field>
);
