import { type FieldHookConfig, useField } from '@src/ui/formik';
import { useInputStyle } from '@src/ui/internal/input-style/input-style';
import { TextField, type TextFieldProps } from '@src/ui/material-ui';

export type TextInputProps = FieldHookConfig<string> &
  Omit<TextFieldProps, 'variant' | 'label' | 'error' | 'helperText'>;

/**
 * Internal component for the text-field
 * Use it only in the ui package
 *
 * @formValueType `string`, or the type of the `type` property
 */
export function TextInput(props: TextInputProps) {
  const [field] = useField(props.name);
  const inputStyle = useInputStyle(props.name);
  const { required, onBlur, ...remainingProps } = props;

  return (
    <TextField
      {...field}
      {...remainingProps}
      id={field.name}
      onBlur={e => {
        field.onBlur(e);
        onBlur?.(e);
      }}
      variant="outlined"
      sx={inputStyle}
    />
  );
}
