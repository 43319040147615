import * as colors from '@src/support/colors';
import clsx from 'clsx';
import type { PropsWithChildren } from 'react';

type TypeProps = 'text' | 'caption' | 'overline' | 'helperText';
type SizeProps = 'medium' | 'small';
type WeightProps = 'extraBold' | 'bold' | 'semiBold' | 'regular';
type AlignProps = 'left' | 'center' | 'right';
type DecorationProps = 'underline';
type WrapProps = 'wrap' | 'nowrap';

type ColorProps = keyof typeof colors;

type TextProps = {
  type?: TypeProps;
  color?: ColorProps;
  size?: SizeProps;
  weight?: WeightProps;
  align?: AlignProps;
  italic?: boolean;
  decoration?: DecorationProps;
  wrap?: WrapProps;
  onClick?: () => void;
  fsExclude?: boolean;
};

type Props = PropsWithChildren<TextProps>;

// Figma: https://www.figma.com/design/A5MIQ4LElRMzA0sL8W6CNp/MPDS---Foundations?node-id=220-521&node-type=frame&t=EkYUafIVZIEwrRoQ-0
export const Text = ({
  children,
  type = 'text',
  color = 'black',
  size = 'medium',
  weight = 'regular',
  wrap,
  align = undefined, // Disabled by default because it changes to display block.
  decoration,
  italic = false,
  onClick,
  fsExclude,
}: Props) => {
  const enabledStyles = buildConfiguration({
    type,
    size,
    weight,
    align,
    italic,
    decoration,
    wrap,
    onClick: !!onClick,
  });

  return (
    <span
      className={clsx('Text', enabledStyles, { 'fs-exclude': fsExclude })}
      style={{ color: colors[color] }}
      onClick={onClick}
    >
      {children}
      <style jsx global>{`
        .Text {
          user-select: none;

          &.onClick-true {
            cursor: pointer;
          }

          &.wrap-wrap {
            text-wrap: wrap;
          }

          &.wrap-nowrap {
            text-wrap: nowrap;
          }

          &.decoration-underline {
            text-decoration: underline;
          }

          &.italic-true {
            font-style: italic;
          }

          &.weight-extraBold {
            font-weight: 900;
          }

          &.weight-bold {
            font-weight: 700;
          }

          &.weight-semiBold {
            font-weight: 600;
          }

          &.weight-regular {
            font-weight: 400;
          }

          &.type-caption,
          &.type-helperText {
            font-size: 0.75rem;
            line-height: 1rem;
          }

          &.type-overline {
            font-size: 0.75rem;
            line-height: 1rem;
            letter-spacing: 0.08em;
            text-transform: uppercase;
          }

          &.type-text {
            &.size-medium {
              font-size: 1rem;
              line-height: 1.25rem;
            }

            &.size-small {
              font-size: 0.875rem;
              line-height: 1.125rem;
            }
          }

          &.align-left {
            display: block;
            text-align: left;
          }

          &.align-center {
            display: block;
            text-align: center;
          }

          &.align-right {
            display: block;
            text-align: right;
          }
        }
      `}</style>
    </span>
  );
};

type ReplaceCallbackWithBoolean<T> = {
  [K in keyof T]: T[K] extends (() => void) | undefined ? boolean : T[K];
};

const buildConfiguration = (props: ReplaceCallbackWithBoolean<TextProps>) => {
  return Object.entries(props).reduce<string[]>((acc, [key, value]) => {
    if (!value) return acc;
    return [...acc, `${key}-${value}`];
  }, []);
};
