import { useWarning } from '@src/contexts/warning-provider/warning-provider';
import { Text } from '@src/ui';
import { useField } from 'formik';
import type { ReactNode } from 'react';

/**
 * Style the helper text based on the field's state
 *
 * Internal component for form fields
 * Use it only in the ui package
 *
 * @param props
 * @returns
 */
export const HelperText = (props: {
  name: string;
  helperText?: ReactNode;
  loading?: boolean;
}) => {
  const [, meta] = useField(props.name);
  const { getWarning } = useWarning();

  const warning = getWarning(props.name);
  const hasError = !!meta.error && meta.touched;
  const hasWarning = !!warning && meta.touched;
  const hasText = !!props.helperText;

  const error = normalizeError(meta.error);

  return props.loading ? (
    <Text type="helperText" color="neutralHighest">
      Loading...
    </Text>
  ) : hasError ? (
    <Text type="helperText" color="semanticErrorHigh">
      {error}
    </Text>
  ) : hasWarning ? (
    <Text type="helperText" color="semanticWarningHigh">
      {warning}
    </Text>
  ) : hasText ? (
    <Text type="helperText">{props.helperText}</Text>
  ) : null;
};

const normalizeError = (error?: string | { [key: string]: string }) => {
  if (typeof error === 'object') {
    return Object.values(error)[0];
  }

  return error;
};
