import type { PropsWithChildren, ReactNode } from 'react';
import { Gap, type GapSizes } from '../../gap';
import { Text } from '../../text';
import { HelperText } from '../helper-text';
import { RequiredMark } from '../required-mark/required-mark';

export type FieldProps = PropsWithChildren<{
  name: string;
  noWrapLabel?: boolean;
  label?: string | ReactNode;
  tooltip?: ReactNode;
  description?: string;
  required?: boolean;
  gap?: GapSizes;
  helperText?: ReactNode;
  loading?: boolean;
}>;

// Internal component for form fields
// Used to wrap a component with a label, description, and tooltip
// Use it only in the ui package
export const Field = ({
  children,
  description,
  label,
  noWrapLabel,
  tooltip,
  name,
  required,
  gap = 'small',
  helperText,
  loading,
}: FieldProps) => {
  return (
    <Gap size={gap} flex>
      {(label || description) && (
        <Gap size="small">
          {label && (
            <label
              htmlFor={name}
              id={`${name}-label`} // Required for Select non-native. https://mui.com/material-ui/react-select/#accessibility
            >
              <Text weight="semiBold" wrap={noWrapLabel ? 'nowrap' : undefined}>
                {label}
                {required && <RequiredMark />}
                {tooltip && (
                  <span style={{ paddingLeft: '0.25rem' }}>{tooltip}</span>
                )}
              </Text>
            </label>
          )}
          {description && (
            <Gap size="small">
              <Text size="small" color="neutralHighest">
                {description}
              </Text>
            </Gap>
          )}
        </Gap>
      )}

      {children}
      <HelperText name={name} helperText={helperText} loading={loading} />
    </Gap>
  );
};
